import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useGET, usePOST } from "./utils";
import { useNavigate } from "react-router-dom";

export const useSignupWithProvider = (provider) => {
  const { handleNotification, trackID } = useStateContext();

  const signup = (token) => {
    usePOST("sign-up/" + provider, {
      data: { token: token.access },
      headers: { "X-Comercify-Visitor": trackID },
    }).then((res) => {
      if (res.type == "error") handleNotification(res);
    });
  };
  return { signup };
};
export const useLogin = () => {
  const { handleNotification } = useStateContext();
  const navigate = useNavigate();
  const handleLogin = (data) => {
    usePOST("login/", { data: data }).then((res) => {
      if (res.type == "error") handleNotification(res);
      if (res.type == "success") {
        navigate("/");
      }
    });
  };
  return { handleLogin };
};

export const useSettings = () => {
  const { handleNotification, user, trackerID } = useStateContext();
  const [refresh, setRefresh] = useState(false);
  const [settings, setSettings] = useState({
    image: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    oldPassword: "",
    password: "",
    passwordConfermation: "",
  });
  useEffect(() => {
    useGET("account/info", { token: user.access, trackerID: trackerID }).then(
      (res) => {
        if (res.type == "success") {
          setSettings({
            image: res.data.image,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            email: res.data.email,
            phoneNumber: res.data.phone_number,
          });
        }
        if (res.type == "error") handleNotification(res);
      }
    );
  }, [refresh]);

  const handleData = (data) => {
    const formData = new FormData();
    formData.append("file", data?.image?.file);
    delete data.image;
    formData.append("json_data", JSON.stringify(data));
    return formData;
  };

  const updaetSettings = () => {
    const data = {
      token: user.access,
      trackerID: trackerID,
    };
    if (settings.image?.file) data.form = handleData(settings);
    else data.data = settings;
    usePOST("account/update/", data).then((res) => {
      handleNotification(res);
      if (res.type == "success") setRefresh(!refresh);
    });
  };

  return { settings, updaetSettings, setSettings };
};
