import React from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { ToolTip } from ".";
import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

export const Logo = () => {
  return (
    <>
      <div className="flex flex-shrink-0 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-9 h-9 mr-4 p-0.5 rounded-md  bg-gradient-to-r from-[#ff80b5] to-[#9089fc] text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
          />
        </svg>
      </div>
      <h2
        style={{
          background: "-webkit-linear-gradient(45deg,  #f72585, #7209b7)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
        className="text-2xl font-bold"
      >
        Comercify
      </h2>
    </>
  );
};

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize, currentLang } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex font-medium items-center gap-5 px-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";

  const normalLink =
    "flex font-medium items-center gap-5 px-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div
      className={
        "h-screen mx-3 md:overflow-hidden md:hover:overflow-y-auto pb-10"
      }
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex font-extrabold tracking-tight"
            >
              <div className="text-center w-full">
                <div
                  dir="ltr"
                  className="px-4 py-2 flex items-center bg-white rounded-md"
                >
                  <Logo />
                </div>
              </div>
            </Link>
            <ToolTip title="Close Menu">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </ToolTip>
          </div>
          <div className="mt-10 ">
            {links.map((item) => (
              <div key={item.title.en}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title[currentLang]}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.name.en.toLowerCase()}`}
                    key={link.name.en}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <span className="text-[17px]">
                      {link.name[currentLang]}
                    </span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
